import styled from 'styled-components'
import {
  system,
  space,
  layout,
  position,
  flexbox,
  color,
  border,
  shadow,
  typography,
} from 'styled-system'

const gridSystem = system({
  area: {
    property: 'gridArea',
  },
  columnStart: {
    property: 'gridColumnStart',
  },
  columnEnd: {
    property: 'gridColumnEnd',
  },
  columnSpan: {
    property: 'gridColumnEnd',
    transform: val => `span ${val}`,
  },
  rowStart: {
    property: 'gridRowStart',
  },
  rowEnd: {
    property: 'gridRowEnd',
  },
  rowSpan: {
    property: 'gridRowEnd',
    transform: val => `span ${val}`,
  },
})

const Box = styled.div`
    box-sizing: border-box;
    min-width: 0;
    ${space}
    ${layout}
    ${position}
    ${flexbox}
    ${color}
    ${border}
    ${shadow}
    ${typography}
    ${gridSystem}
`

export default Box
