import NumberFormat from 'react-number-format'

function BankingFormat(props) {
    const { inputRef, onChange, ...other } = props

    return (
        <NumberFormat
            {...other}
            defaultValue={props.defaultValue}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange(values)
            }}
            thousandSeparator
            allowLeadingZeros={false}
            allowNegative={false}
            isNumericString
            decimalScale="0"
            isAllowed={(values) => { return values.value <= 9 ? true : false }}
        // suffix="%"
        />
    )
}

export default BankingFormat