import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "3rem",
    fontWeight: "600",
    fontFamily: "Poppins",
    color: theme.palette.text.primary,
  },
}));
export default function PageHeaders({ value }) {
  const classes = useStyles();
  return (
    <Typography className={classes.text} variant="h4">
      {value}
    </Typography>
  );
}
