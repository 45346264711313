// import { Button, makeStyles } from "@material-ui/core";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import clsx from "clsx";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeaders } from "../../typography/pageHeaders";

export default function HeaderContainer({
  header,
  button = false,
  blueButton = false,
  buttonIcon,
  path = "/",
  style,
  bkColor,
}) {
  const navigate = useNavigate();
  const handleOnClick = useCallback((text) => navigate(`${text}`), [navigate]);
  const useStyles = makeStyles((theme) => ({
    header: {
      //   backgroundColor: theme.palette.background.default,
      padding: "4rem 0 3.5rem 0",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      ...style,
    },
    bluebutton: {
      color: "#014e65 !important",
      borderColor: "#014e65 !important",
      "&:hover": {
        backgroundColor: "#014e6520 !important",
      },
    },
  }));
  const classes = useStyles();
  return (
    <Box
      sx={{ backgroundColor: bkColor ? bkColor : null }}
      className={classes.header}
    >
      <PageHeaders value={header} />
      {button === false ? (
        ""
      ) : (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleOnClick(path)}
          className={clsx(blueButton && classes.bluebutton)}
          startIcon={buttonIcon}
        >
          {button}
        </Button>
      )}
    </Box>
  );
}
