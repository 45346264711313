import React from "react";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import InputField from "../inputField/InputField";
import { makeStyles } from "@mui/styles";

const Datepicker = ({ name, label, form, validate }) => {
  const [value, setValue] = React.useState(new Date());
  let id = label.split(" ").join("").split("");

  id.splice(0, 1, id[0].toLowerCase());
  const handleChange = (newValue) => {
    let d = new Date(newValue);
    let date = `${
      d.getMonth() + 1 > 10 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1)
    }/${d.getDate() > 9 ? d.getDate() : "0" + d.getDate()}/${d.getFullYear()}`;
    setValue(newValue);
    form.change(name, date);
  };
  const useStyles = makeStyles((theme) => ({
    root: {},
  }));
  const classes = useStyles();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        // className={classes.root}
        label={label}
        inputFormat="MM/dd/yyyy"
        value={value}
        onChange={handleChange}
        renderInput={(params) => (
          <InputField validate={validate} {...params} name={name} />
        )}
      />
    </LocalizationProvider>
  );
};

export default Datepicker;
