import { Button, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Form } from "react-final-form";
import { irisGetUsers } from "../../../server/irisCrm";
import DropDown from "../../components/inputs/dropDown/DropDown";
import CustomFlex from "../../components/structure/customFlex/CustomFlex";
import { useNavigate } from "react-router-dom";
// import { useTemplate } from "../../../server/docusign";
// import { getAccountInformation } from "docusign-esign";
const Home = ({ leadInfo, setLeadInfo }) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      borderRadius: ".8rem",
      position: "absolute",
      padding: "2rem",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "75rem",
      height: "55.6rem",
      zIndex: "100",
      [theme.breakpoints.down("sm")]: {
        width: "50rem",
        height: "55.6rem",
      },
    },
    form: {
      width: "100% !important",
      "& > *": {
        width: "100% !important",
      },
    },
    fields: {
      "& > *": {
        marginBottom: "2.5rem",
      },
    },
  }));
  const classes = useStyles();
  // console.log(test);
  const [resellers, setResellers] = React.useState("");
  const [referral, setReferral] = React.useState("");
  const [processor, setProcessor] = React.useState("");
  const [users, setUsers] = React.useState([]);

  const navigate = useNavigate();
  // console.log(resellers);
  if (users.length === 0) {
    irisGetUsers(setUsers);
  } else {
  }
  // console.log("hey", users);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const resellerName = (value) =>
    users.find((user) => user.full_name === value) ? undefined : true;
  const provalidate = (value) =>
    ["WorldPay", "PaySafe", "Shift4"].find((proccesor) => proccesor === value)
      ? undefined
      : true;
  const referralName = (value) =>
    users.find((user) => user.full_name === value) ||
    value === undefined ||
    value === ""
      ? undefined
      : true;

  const onSubmit = async (values) => {
    await sleep(300);
    let vals = { ...values };
    let holder = [];

    users.map((each) => {
      if (vals.resellerName !== vals.referralName) {
        if (each.full_name === vals.resellerName) {
          holder.push({
            reseller: {
              name: vals.resellerName,
              id: each.id,
              email: each.email,
            },
          });
        } else if (each.full_name === vals.referralName) {
          holder.push({
            referral: {
              name: vals.referralName,
              id: each.id,
              email: each.email,
            },
          });
        } else {
          // holder.push(null);
        }
      } else {
        if (each.full_name === vals.resellerName) {
          holder.push({
            reseller: {
              name: vals.resellerName,
              id: each.id,
              email: each.email,
            },
          });
        }
      }
    });

    console.log("values", holder);
    // console.log("users", users);

    await setLeadInfo(holder);
    const form =
      (await values.processor) === "WorldPay"
        ? "wp"
        : values.processor === "PaySafe"
        ? "ps"
        : values.proccessor === "Shift4"
        ? "s4"
        : "err";
    await navigate(`/reseller/${form}`);
  };
  return (
    <Paper className={classes.paper}>
      {/* <buttom onClick={authToken}>template</buttom> */}
      {/* <buttom onClick={useTemplate}>static</buttom> */}
      <Form
        onSubmit={onSubmit}
        // initialValues={formData}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <CustomFlex direction="column" alignItems="none">
              <CustomFlex direction="column" margin="5.2rem 0 5.6rem 0">
                <Typography
                  variant="h4"
                  style={{ fontSize: "2.5rem", fontWeight: "500" }}
                >
                  Merchant Processor Aplication
                </Typography>
              </CustomFlex>
              <CustomFlex
                position="relative"
                direction="column"
                className={classes.fields}
              >
                <DropDown
                  validate={resellerName}
                  form={form}
                  options={users}
                  width="53.4"
                  onClick={setResellers(false)}
                  closeOnClick
                  parentWidth="100%"
                  label="Reseller Name"
                  value={resellers.full_name}
                  name="resellerName"
                  onSelect={(e) => {
                    setResellers(e);
                    form.change("resellerName", e.full_name);
                  }}
                  onChange={(e) => {
                    setResellers(e);
                    form.change("resellerName", e);
                  }}
                  filterBy={(each) => each.full_name}
                  renderOption={(option) => {
                    return (
                      <CustomFlex
                        alignItems="start"
                        direction="column"
                        width="100%"
                        padding="1rem 0"
                      >
                        <Typography variant="h6" style={{ fontWeight: "600" }}>
                          {option.full_name}
                        </Typography>
                      </CustomFlex>
                    );
                  }}
                />
                <DropDown
                  options={users}
                  width="53.4"
                  onChange={(e) => {
                    setReferral(e);
                    form.change("referralName", e);
                  }}
                  validate={referralName}
                  closeOnClick
                  parentWidth="100%"
                  label="Referral Name"
                  value={referral.full_name}
                  name="referralName"
                  onSelect={(e) => {
                    setReferral(e);
                    form.change("referralName", e.full_name);
                  }}
                  filterBy={(each) => each.full_name}
                  renderOption={(option) => {
                    return (
                      <CustomFlex
                        alignItems="start"
                        direction="column"
                        width="100%"
                        padding="1rem 0"
                      >
                        <Typography variant="h6" style={{ fontWeight: "600" }}>
                          {option.full_name}
                        </Typography>
                      </CustomFlex>
                    );
                  }}
                />

                <DropDown
                  options={["WorldPay", "PaySafe", "Shift4"]}
                  width="53.4"
                  onChange={(e) => {
                    setProcessor(e);
                    form.change("processor", e);
                  }}
                  closeOnClick
                  parentWidth="100%"
                  label="Processor"
                  validate={provalidate}
                  value={processor}
                  name="processor"
                  onSelect={(e) => {
                    setProcessor(e);
                    form.change("processor", e);
                  }}
                  filterBy={(each) => each}
                  renderOption={(option) => {
                    return (
                      <CustomFlex
                        alignItems="start"
                        direction="column"
                        width="100%"
                        padding="1rem 0"
                      >
                        <Typography variant="h6" style={{ fontWeight: "600" }}>
                          {option}
                        </Typography>
                      </CustomFlex>
                    );
                  }}
                />
                <Button
                  variant="contained"
                  sx={{ width: "53.4%", mt: "4rem", color: "background.paper" }}
                  type="submit"
                >
                  Continue
                </Button>
              </CustomFlex>
            </CustomFlex>
          </form>
        )}
      />
    </Paper>
  );
};

export default Home;
