import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Box, Typography } from "@mui/material";

export default function Divider({
  position = "100",
  variant = "section",
  size,
  color,
  styles,
  width = "100%",
  content = false,
  hVariant,
  cStyle,
}) {
  const divRef = useRef();
  useEffect(() => {
    ReactDOM.findDOMNode(divRef.current).parentNode.style.position = "relative";
  }, [divRef]);
  const useStyles = makeStyles((theme) => ({
    body: {
      width: width,

      height:
        variant === "header" ? "3px" : variant === "custom" ? size : "1px",
      position: "absolute",
      background:
        variant === "header"
          ? theme.palette.background.default
          : variant === "custom"
          ? null
          : theme.palette.background.darker,
      right: "0",
      top: `${position}%`,
      left: "50%",
      transform: "translateX(-50%)",
      transition: "all .2s ease",
      ...styles,
    },
  }));

  const classes = useStyles();

  return (
    <Box
      id="divider"
      sx={variant === "custom" ? { backgroundColor: color } : null}
      ref={divRef}
      className={classes.body}
      //   style={style}
    >
      <Typography style={{ ...cStyle }} variant={hVariant}>
        {content ? content : null}{" "}
      </Typography>
    </Box>
  );
}
