import SvgIcon from "@mui/material/SvgIcon";
import { makeStyles } from "@mui/styles";
import React from "react";
import * as Icons from "../../assets/icons";

const Iconic = ({
  icon,
  variant = false,
  boxToSize = false,
  viewBox = "0 0 20 18",
  height,
  width,
  styles,
}) => {
  const boxSize = (dimension) => {
    const splited = viewBox.split(" ");
    const size = boxToSize
      ? dimension === "w"
        ? splited[2]
        : splited[3]
      : "auto";
    return size;
  };

  const useStyles = makeStyles((theme) => ({
    svgColor: {
      fill: `${theme.palette.text.primary} !important`,
      height: boxToSize ? `${boxSize("h")}px` : height,
      width: boxToSize ? `${boxSize("w")}px` : width,
    },
    displayColor: {
      fill: `${theme.palette.background.darker} !important`,
      height: boxToSize ? `${boxSize("h")}px` : height,
      width: boxToSize ? `${boxSize("w")}px` : width,
    },
  }));
  const chosenStyle = () => {
    if (variant === false) {
      return classes.svgColor;
    } else {
      if (variant === "display") {
        return classes.displayColor;
      } else {
        return variant;
      }
    }
  };
  const classes = useStyles();
  return (
    <SvgIcon
      viewBox={viewBox}
      className={`${chosenStyle()} `}
      component={Icons[icon]}
      style={{ ...styles }}
    />
  );
};
export default Iconic;
