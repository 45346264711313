import axios from 'axios'

const irisGetUsers = async (stateSetter) => {
    var origin = window.location.protocol + "//" + window.location.host;
    let config = {
        method: "get",
        url: "https://request-handeller.herokuapp.com/http://portal.irelandpay.com/api/v1/leads/users",
        headers: {
            "X-API-KEY": "HPJ8ENZEvkhHXyDpLwQNPU9B9jhzSyLUbYtuLdp7LR2HXBYF5h",

        },
    };

    //   const retriveUsers = async () => {
    await axios(config)
        .then((response) => {
            // console.log(JSON.stringify(response.data));
            const data = response.data;
            stateSetter(data.data);
        })
        .catch((error) => {
            console.log(error);
        });
    //   };
}


const leadCreator = (proccesor, users, values) => {
    // var origin = window.location.protocletol + "//" + window.location.host;

    let phone = values.businessPhone.split('')
    phone.splice(3, 0, '-')
    phone.splice(7, 0, '-')
    console.log(phone.join(''))
    console.log(users)
    let userHolder = users[0].reseller ? users : users.reverse()
    console.log(userHolder)
    let finalUsers = userHolder.map((user) => {
        console.log(user)
        if (user.reseller) {
            return user.reseller.id
        } else {
            return user.referral.id
        }
    })

    let contactName = values.contactName.split(' ')
    let data = JSON.stringify({
        "status": 12,
        "group": 1,
        "users": finalUsers,
        "fields": [
            {
                "id": 1246,
                "name": "Proccesor",
                "value": proccesor
            },
            {
                "id": 1303,
                "name": "repId",
                "value": values.repID
            },
            {
                "id": 543,
                "name": "Legal Name",
                "value": values.businessLegalName
            },
            {
                "id": 552,
                "name": "DBA",
                "value": values.businessNameDBA
            },
            {
                "id": 556,
                "name": "ContactFirstName",
                "value": contactName[0]
            },
            {
                "id": 1217,
                "name": "ContactLastName",
                "value": contactName[1] ? contactName[1] : ""
            },
            {
                "id": 1214,
                "name": "ContactEmail",
                "value": values.contactEmail
            },
            {
                "id": 558,
                "name": "DBA Address",
                "value": values.businessAddress
            },
            {
                "id": 560,
                "name": "DBA City",
                "value": values.businesCity
            },
            {
                "id": 562,
                "name": "DBA State",
                "value": values.businesState
            },
            {
                "id": 564,
                "name": "Dba Zip",
                "value": values.businesZip
            },
            {
                "id": 576,
                "name": "Dba Phone",
                "value": phone.join('')
            },
            {
                "id": 572,
                "name": "Federal Tax ID",
                "value": values.federalTaxID
            },
            {
                "id": 922,
                "name": "routing",
                "value": values.routingNumber
            },
            {
                "id": 925,
                "name": "account",
                "value": values.accountNumber
            }

        ]
    });

    let config = {


        method: 'post',
        url: 'https://request-handeller.herokuapp.com/https://portal.irelandpay.com/api/v1/leads/',
        headers: {
            'X-API-KEY': 'HPJ8ENZEvkhHXyDpLwQNPU9B9jhzSyLUbYtuLdp7LR2HXBYF5h',
            'Content-Type': 'application/json',

        },

        data: data
    };

    axios(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
            console.log(error);
        });

}


export { irisGetUsers, leadCreator }