import NumberFormat from 'react-number-format'

function SNumberFormat(props) {
    const { inputRef, onChange, ...other } = props

    return (
        <NumberFormat
            {...other}
            defaultValue={props.defaultValue}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                })
            }}
            thousandSeparator
            allowLeadingZeros={false}
            allowNegative={false}
            isNumericString
            type="text"
            format=" ##"
        // suffix="%"
        />
    )
}

export default SNumberFormat
