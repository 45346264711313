import React from "react";
import { makeStyles } from "@mui/styles";
import { Field } from "react-final-form";
import { default as Flex } from "../../structure/flex";
import {
  TextField,
  InputLabel,
  Typography,
  InputAdornment,
} from "@mui/material";
import { DollarSign, PercentSign } from "../../../assets/icons";

const InputField = ({
  fieldLess = false,
  width = "100%",
  minWidth = "2rem",
  parentWidth, // to target the parent of the input
  label,
  id,
  errMesgRemove = false,
  inputStyle,
  labelStyle,
  adorment,
  value,
  cEndAdorment = false,
  maxLength = 200,
  validate,
  ...props
}) => {
  const [error, setError] = React.useState(false);

  const errorSetter = (err, touch) => {
    if (err && touch) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };
  const useStyles = makeStyles((theme) => ({
    sizing: {
      width: "100%",
      "& > p": {
        display: `${errMesgRemove ? "none" : "block"}`,
      },
      "& > * ": {
        height: props.height,
        ...inputStyle,
      },
      "& > * > *": {
        borderColor: theme.palette.text.lightText,
        borderWidth: `${error ? "1px !important" : "1px"}`,
      },
    },
    placeStyle: {
      "&::placeholder": {
        color: theme.palette.text.lightText,
        opacity: 1,
        fontSize: props.font,
        padding: props.padd,
      },
    },
    label: {
      fontFamily: "Poppins",
      color: theme.palette.text.primary,
      ...labelStyle,
    },
    subLabel: {
      fontFamily: "Poppins",
      color: theme.palette.text.lightText,
      fontSize: "1.2rem",
      marginBottom: ".5rem",
    },
  }));
  const classes = useStyles();

  const getHelperText = (meta) => {
    if (!meta.touched || !meta.error) {
      return null;
    }
    return meta.error;
  };

  return fieldLess ? (
    <Flex
      style={{
        flexDirection: "column",
        // alignItems: "center",
        minWidth: minWidth,
        width: width,
      }}
    >
      {label ? (
        <InputLabel className={classes.label} htmlFor={label ? label : ""}>
          {label}
        </InputLabel>
      ) : null}
      {props.subLabel ? (
        <Typography className={classes.subLabel}>{props.subLabel}</Typography>
      ) : null}
      <TextField
        defaultValue={value}
        type={props.type}
        className={classes.sizing}
        InputProps={{
          inputComponent: props.cformat,
          classes: { input: classes.placeStyle },
        }}
        // inputProps={}
        id={label}
        variant="outlined"
        label={false}
        size="small"
        {...props}
      />
    </Flex>
  ) : (
    <Field
      validate={validate}
      format={props.format}
      component={React.ComponentType}
      name={props.name}
      render={({ input, meta }) => (
        <Flex
          id={id}
          style={{
            flexDirection: "column",
            minWidth: minWidth,
            width: width,
          }}
        >
          {label ? (
            <InputLabel className={classes.label} htmlFor={label ? label : ""}>
              {label}
            </InputLabel>
          ) : null}
          {props.subLabel ? (
            <Typography className={classes.subLabel}>
              {props.subLabel}
            </Typography>
          ) : null}
          <TextField
            defaultValue={value}
            type="field"
            error={errorSetter(meta.error, meta.touched)}
            helperText={getHelperText(meta)}
            className={classes.sizing}
            onBlur={(e) => {
              console.log(e.target.value);
            }}
            InputProps={{
              inputComponent: props.cformat,
              classes: { input: classes.placeStyle },
              startAdornment:
                adorment === "dollar" ? (
                  <div style={{ width: "8px !important", height: "14px" }}>
                    <DollarSign />
                  </div>
                ) : null,
              endAdornment:
                adorment === "percent" ? (
                  <div style={{ width: "14px !important", height: "16px" }}>
                    <PercentSign />
                  </div>
                ) : cEndAdorment ? (
                  cEndAdorment
                ) : null,
              // maxLength: maxLength,
            }}
            placeholder={props.placeholder ? props.placeholder : ""}
            id={label}
            // onChange={props.onChange ? props.onChange : null}
            variant="outlined"
            label={false}
            size="small"
            {...input}
            {...props}
          />
        </Flex>
      )}
    />
  );
};

export default InputField;
