import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect } from "react";
import { Field } from "react-final-form";
import { CustomFlex } from "../../structure/customFlex";
const Radioinput = ({
  column = false,
  content,
  title,
  validate,
  setter = false,
  styles,
  errorText = false,
  onChange,
  form,
}) => {
  let id = title
    .split(" ")
    .join("")
    .split("*")
    .join("")
    .split("?")
    .join("")
    .split("");

  id.splice(0, 1, id[0].toLowerCase());
  const [choice, setChoice] = React.useState(null);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    setChoice(
      form.getFieldState(id.join(""))?.value
        ? form.getFieldState(id.join(""))?.value
        : null
    );
  }, [form, id]);

  const contentRender = () => {
    return content.map((each) => {
      return (
        <FormControlLabel
          onClick={(e) => {
            // console.log("hey", each);
            // form.change(id.join(""), each);
            if (setter === false) {
            } else {
              setter(each);
            }
          }}
          key={each}
          value={each}
          control={
            <Field
              name={id.join("")}
              onChange={onChange}
              validate={validate}
              type="radio"
            >
              {(props) => (
                <>
                  {/* {console.log(props)} */}
                  {!props.meta.valid && props.meta.modified
                    ? setError(true)
                    : setError(false)}

                  <Radio
                    name={props.input.name}
                    value={each}
                    onChange={props.input.onChange}
                  />
                </>
              )}
            </Field>
          }
          label={each}
        />
      );
    });
  };

  return (
    <FormControl style={{ ...styles }} error={error}>
      <FormLabel
        style={{ fontWeight: 500 }}
        id="demo-row-radio-buttons-group-label"
      >
        {title}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={choice}
        onChange={(e) => {
          setChoice(e.target.value);
        }}
      >
        <CustomFlex direction={column ? "column" : null} items="baseline">
          {contentRender()}
        </CustomFlex>
      </RadioGroup>
      {error ? <FormHelperText>{errorText}</FormHelperText> : null}
    </FormControl>
  );
};

export default Radioinput;
