import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { CustomDivider } from "../../../../../components/partials/customDividers";
import { CustomFlex } from "../../../../../components/structure/customFlex";
import { RadioInput } from "../../../../../components/inputs/radioInputs";
import { DropDown } from "../../../../../components/inputs/dropDown";
import InputField from "../../../../../components/inputs/inputField/InputField";
import { InputGrid } from "../../../../../components/inputs/inputGrid";
import { ButtonFooter } from "../../../../../components/structure/buttomFooter";
import { validate } from "../../../utils";
import { MoneyFormat, NumberFormat } from "../../../../../../utils";
const ThirdPage = ({ form, counter }) => {
  const [pricingType, setPricingType] = React.useState("");
  const [pinDebit, setPinDebit] = React.useState("No");
  const [amex, setAmex] = React.useState("No");
  const useStyles = makeStyles((theme) => ({
    sec1: {
      paddingBottom: "2.25rem",
      "& > div": {
        margin: "0 7.2rem",
      },
    },
    sec2: {
      "& #pricingContainer #top": {
        marginBottom: "4rem",
      },
    },
    sec3: {},
    sec4: {
      "& #fees": {
        border: `1px solid ${theme.palette.text.primary}`,
        borderRadius: ".4rem",
        padding: "1.6rem 0rem 0rem 0rem",
        width: "91%",
        marginTop: "1.6rem",
        "& > div": {
          maxWidth: "29.2rem",
          margin: "0 2rem 1.6rem 2rem",
          "& > div > div > svg": {
            // width: "1.05rem",
          },
        },
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      <CustomFlex margin="0 7.2rem 10rem 7.2rem">
        <CustomDivider
          variant="custom"
          cStyle={{ fontSize: "2.4rem", fontWeight: "500" }}
          content={`Credit & Debit Card Transaction Pricing`}
          size="8.1rem"
          color="background.default"
          width="calc(100% + 14.4rem)"
          styles={{
            "& > *": {
              margin: "2rem 0 0 7.2rem",
            },
          }}
        />
      </CustomFlex>
      <CustomFlex
        className={classes.sec1}
        justifyContent="start"
        items="baseline"
        // margin="0 7.2rem 0 7.2rem"
      >
        <RadioInput
          validate={validate.onlyReq}
          content={["Retail", "Restaurant", "Mail/Telephone Order", "Internet"]}
          title="Bussiness Type *"
          form={form}
          column
        />
        <RadioInput
          validate={validate.onlyReq}
          content={["Daily", "Monthly"]}
          title="Billing Frequency *"
          form={form}
        />
        <CustomDivider
          variant="custom"
          size="1px"
          color="background.darker"
          width="180%"
        />
      </CustomFlex>
      {/* <CustomFlex
        className={classes.sec2}
        direction="column"
        justifyContent="start"
        items="flex-start"
        margin="2.35rem 7.2rem 0 7.2rem"
      >
        <CustomDivider
          variant="custom"
          size="1px"
          color="background.darker"
          width="180%"
        />
        <CustomFlex
          // direction="column"
          items="flex-start"
          width="100%"
          id="pricingContainer"
          marginRight="6.4rem"
          justifyContent="start"
        >
          <CustomFlex width="20.5%" id="top" position="relative">
            <DropDown
              form={form}
              options={["Flat Rate", "Tiered Pricing", "Interchange + Pricing"]}
              width="100"
              closeOnClick
              validate={validate.pricingvalidate}
              parentWidth="100%"
              label="Pricing *"
              value={pricingType}
              name="pricing"
              onSelect={(e) => {
                setPricingType(e);
                form.change("pricing", e);
              }}
              filterBy={(each) => each}
              renderOption={(option) => {
                return (
                  <CustomFlex
                    alignItems="start"
                    direction="column"
                    padding="1rem 0"
                  >
                    <Typography variant="h6" style={{ fontWeight: "600" }}>
                      {option}
                    </Typography>
                  </CustomFlex>
                );
              }}
            />
          </CustomFlex>
          <CustomFlex margin="0  0 1.6rem 6.4rem">
            {pricingType === "Flat Rate" ? (
              <InputGrid
                validate={(value) =>
                  validate.onlyReqIfField(value, "pricing", "Flat Rate", form)
                }
                prefix="flatRate"
                ratio={[1, 2]}
                lanes={false}
                labels={["Discount Rate *", "Transaction Fee *"]}
                content={[
                  { loc: [1, 1], type: "percent" },
                  { loc: [1, 2], type: "dollar" },
                ]}
              />
            ) : pricingType === "Tiered Pricing" ? (
              <InputGrid
                validate={(value) =>
                  validate.onlyReqIfField(
                    value,
                    "pricing",
                    "Tiered Pricing",
                    form
                  )
                }
                prefix="tieredPricing"
                ratio={[3, 2]}
                lanes={["Qualified", "Mid-Qualified", "Non-Qualified"]}
                labels={["Discount Rate *", "Transaction Fee *"]}
                content={[
                  { loc: [1, 1], type: "percent" },
                  { loc: [1, 2], type: "dollar" },

                  { loc: [2, 1], type: "percent" },
                  { loc: [2, 2], type: "dollar" },

                  { loc: [3, 1], type: "percent" },
                  { loc: [3, 2], type: "dollar" },
                ]}
              />
            ) : pricingType === "Interchange + Pricing" ? (
              <InputGrid
                validate={(value) =>
                  validate.onlyReqIfField(
                    value,
                    "pricing",
                    "Interchange + Pricing",
                    form
                  )
                }
                prefix="interchange"
                ratio={[1, 2]}
                lanes={false}
                labels={["Discount Rate *", "Transaction Fee *"]}
                content={[
                  { loc: [1, 1], type: "percent" },
                  { loc: [1, 2], type: "dollar" },
                ]}
              />
            ) : null}
          </CustomFlex>
        </CustomFlex>
        <CustomFlex marginBottom="2.35rem" direction="column" items="unset">
          <RadioInput
            validate={validate.onlyReq}
            content={["Yes", "No"]}
            title="Existing American Express Number *"
            setter={setAmex}
            form={form}
          />
          <CustomFlex marginTop={amex === "Yes" ? "1.35rem" : null}>
            {amex === "Yes" ? (
              <InputField
                name="amexNum"
                cformat={NumberFormat}
                validate={(value) =>
                  validate.onlyReqIfField(
                    value,
                    "existingAmericanExpressNumber",
                    "Yes",
                    form
                  )
                }
              />
            ) : null}
          </CustomFlex>
        </CustomFlex>
      </CustomFlex> */}
      {/* <CustomFlex
        justifyContent="start"
        className={classes.sec3}
        margin="2.35rem 7.2rem 0 7.2rem"
        paddingBottom="2.35rem"
      >
        <CustomDivider
          variant="custom"
          size="1px"
          color="background.darker"
          width="180%"
        />
        <CustomFlex>
          <RadioInput
            validate={validate.onlyReq}
            title="Pin Debit Pricing *"
            content={["Yes", "No"]}
            setter={setPinDebit}
            form={form}
          />
        </CustomFlex>
        <CustomFlex paddingLeft="10%">
          {pinDebit === "Yes" ? (
            <InputGrid
              validate={(value) =>
                validate.onlyReqIfField(value, "pinDebitPricing", "Yes", form)
              }
              prefix="pinDebit"
              ratio={[1, 3]}
              labels={[
                "Monthly Hosting Fee *",
                "Discount Rate *",
                "Transaction Fee *",
              ]}
              content={[
                { loc: [1, 1], type: "dollar" },
                { loc: [1, 2], type: "percent" },
                { loc: [1, 3], type: "dollar" },
              ]}
              lanes={false}
            />
          ) : null}
        </CustomFlex>
      </CustomFlex> */}
      {/* <CustomFlex
        direction="column"
        items="baseline"
        paddingBottom="2.35rem"
        margin="2.35rem 7.2rem 0 7.2rem"
        className={classes.sec4}
      >
        <CustomFlex>
          <Typography variant="h6">Occurrence Fees</Typography>
        </CustomFlex>
        <CustomFlex id="fees" flexWrap="wrap" justifyContent="start">
          <InputField
            cformat={MoneyFormat}
            errMesgRemove
            validate={validate.onlyReq}
            name="onFileFee"
            label="On File Fee *"
            adorment="dollar"
            cEndAdorment="/month"
          />
          <InputField
            cformat={MoneyFormat}
            errMesgRemove
            validate={validate.onlyReq}
            name="batchFee"
            label="Batch Fee *"
            adorment="dollar"
            cEndAdorment="/item"
          />
          <InputField
            cformat={MoneyFormat}
            errMesgRemove
            validate={validate.onlyReq}
            name="voiceDial"
            label="Voice Auth / DialPay *"
            adorment="dollar"
            cEndAdorment="/item"
          />
          <InputField
            cformat={MoneyFormat}
            errMesgRemove
            validate={validate.onlyReq}
            name="retrivalFee"
            label="Retrival Fee *"
            adorment="dollar"
            cEndAdorment="/each"
          />
          <InputField
            cformat={MoneyFormat}
            errMesgRemove
            validate={validate.onlyReq}
            name="minimunBill"
            label="Minimun Bill"
            adorment="dollar"
            cEndAdorment="/month"
          />
          <InputField
            cformat={MoneyFormat}
            errMesgRemove
            validate={validate.onlyReq}
            name="earlyDeconFee"
            label="Early Deconversion Fee"
            adorment="dollar"
          />
          <InputField
            cformat={MoneyFormat}
            errMesgRemove
            validate={validate.onlyReq}
            name="chargebackFee"
            label="Chargeback Fee"
            adorment="dollar"
            cEndAdorment="/month"
          />
        </CustomFlex>
      </CustomFlex> */}
      <div style={{ height: "8.8rem" }}></div>
      <ButtonFooter form={form} counter={counter} />
    </>
  );
};

export default ThirdPage;
