const colors = {
  pureWhite: "#FFFFFF",
  cashCash: "#05C806",
  moneyWellSpent: "#FF8373",
  makeItRain: "#34CFFC",
  bandsInTheSand: "#FFDA83",
  theLastCheck: "#EBF0F3",
  steelsAndDeals: "#f5f6f8",
  greyPay: "#E4E8EC",
  paperTrail: "#EEEEEE",
  platinumCard: "#DEDEDE",
  sliverOfSilver: "#c2c2c2",
  dankBank: "#A5A5A5",
  fiftyPaysLighter: "#8D8D8D",
  fiftyShadesOfPay: "#5C5C5C",
  inTheBlack: "#292929",
  activeGreen: "#E4F8E4",
  cashDiscount: "#7CDE7C",
  luckyClover: "#00A706",
  dollarBills: "#1B8A28",
  moneyMaker: "#084936",
  stacksOnStacks: "#0B2E23",
  havingWithdrawals: "#FFB7AD",
  girlsJustWannaHaveFunds: "#FF5666",
  overdraftFee: "#D73343",
  errorRed: "#B80012",
  brickMortar: "#66000A",
  hoverGreen: "#F2fcf2",
  siliconBeach: "#D6EFFF",
  swipeHype: "#03AADD",
  oceansOfPlastic: "#027CA1",
  magneticStripe: "#014E65",
  profitLossFloss: "#002E3D",
  stocksBlondes: "#FFEDC2",
  wealthHealth: "#FFC847",
  potOfGold: "#F5AB00",
  luckOfTheIrish: "#D6871F",
  drippingInSold: "#A16517",
  finTechStartup: "#C8D6F9",
  whatsMyRoi: "#6E94ED",
  getThatPaper: "#487EFA",
  moneyMarket: "#1B54DA",
  bullsBears: "#123891",
  wolfOfWallStreet: "#091C49",
  putItOnMyCard: "#D5CEF3",
  plasticQueen: "#AB9BE8",
  ventureCapital: "#8F7AE1",
  giveMeATaxBreak: "#6448D5",
  philanthropreneur: "#331E85",
  swipeLife: "#201353",
  thePinkBanker: "#E6C6DE",
  seedsLeads: "#C77FB4",
  bossMoves: "#B34295",
  theFamilyJewels: "#A4137E",
  allAboutTheBenjamins: "#7D0F60",
  inGodWeTrustFund: "#490938",
  // backgroundGrey: '#C2C2C2',
  // hoverBlue: '#F5FBFF',
};

export default colors;
