import NumberFormat from 'react-number-format'

function MoneyFormat(props) {
	const { inputRef, onChange, defaultValue, ...other } = props

	let value = defaultValue
		? (Math.round(defaultValue * 100) / 100).toFixed(2)
		: null

	return (
		<NumberFormat
			{...other}
			defaultValue={value}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				})
			}}
			thousandSeparator
			allowLeadingZeros={false}
			allowNegative={false}
			isNumericString
			decimalScale="2"
			isAllowed={(values) => { return values.value.split(".")[0].split("").length <= 12 ? true : false }}
		// prefix="$"
		/>
	)
}

export default MoneyFormat
