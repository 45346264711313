import React, { useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import { CustomFlex } from "../../structure/customFlex";
import { Field } from "react-final-form";
const CheckboxInput = ({
  column = false,
  content,
  errorText,
  title,
  form,
  setter = false,
  styles,
  validate,
  onChange,
}) => {
  let id = title.split(" ").join("").split("*").join("").split("");

  id.splice(0, 1, id[0].toLowerCase());
  const [check, setCheck] = React.useState([]);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    setCheck(
      form.getFieldState(id.join(""))?.value
        ? form.getFieldState(id.join(""))?.value
        : null
    );
  }, [form, id]);

  const runCheck = (checked) => {
    const unique = [...new Set(check)];
    if (checked.target.checked) {
      return unique?.find((item) => item === checked.target.value)
        ? null
        : setCheck([...unique, checked.target.value]);
    } else {
      if (unique?.find((item) => item === checked.target.value)) {
        let arr = [...unique.filter((item) => item !== checked.target.value)];

        setCheck(arr);
      }
    }
  };

  // console.log("check", check);
  const contentRender = () => {
    return content.map((each) => {
      return (
        <FormControlLabel
          onClick={(e) => {
            runCheck(e);

            if (setter === false) {
            } else {
              setter(each);
            }
          }}
          key={each}
          value={each}
          control={
            <Field
              name={id.join("")}
              onChange={(e) => {
                setCheck(each);
              }}
              validate={(value) => validate(value)}
              type="checkbox"
            >
              {(props) => (
                <>
                  {!props.meta.valid && props.meta.length === 0
                    ? setError(true)
                    : setError(false)}
                  <Checkbox
                    checked={
                      check?.find((item) => item === each) ? true : false
                    }
                    name={props.input.name}
                    value={each}
                    onChange={props.input.onChange}
                  />
                </>
              )}
            </Field>
          }
          label={each}
        />
      );
    });
  };

  return (
    <FormControl style={{ ...styles }} error={error}>
      <FormLabel
        style={{ fontWeight: 500 }}
        id="demo-row-radio-buttons-group-label"
      >
        {title}
      </FormLabel>
      <FormGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <CustomFlex direction={column ? "column" : null} items="baseline">
          {contentRender()}
        </CustomFlex>
      </FormGroup>
      {error ? <FormHelperText>{errorText}</FormHelperText> : null}
    </FormControl>
  );
};

export default CheckboxInput;
