import { makeStyles } from "@mui/styles";
import React from "react";
import InputField from "../../../../../components/inputs/inputField/InputField";
import { CustomDivider } from "../../../../../components/partials/customDividers";
import { CustomFlex } from "../../../../../components/structure/customFlex";
import { RadioInput } from "../../../../../components/inputs/radioInputs";
import { DropDown } from "../../../../../components/inputs/dropDown";
import { Typography } from "@mui/material";
import { ButtonFooter } from "../../../../../components/structure/buttomFooter";
import {
  SSNFormat,
  PhoneFormat,
  DOBFormat,
  PercentageFormat,
  ZipCodeFormat,
  states,
} from "../../../../../../utils";
import { validate } from "../../../utils";

export default function FirstPage({ form, counter }) {
  const [entity, setEntity] = React.useState("");
  const [contactState, setContactState] = React.useState("");
  const [billingAddress, setBillingAddress] = React.useState(null);
  const [controllerInfo, setControllerInfo] = React.useState(null);
  const [controllerState, setControllerState] = React.useState("");

  const useStyles = makeStyles((theme) => ({
    sec1: {
      "&  #inputs > div": {
        marginBottom: "2.4rem",
      },
    },
    sec2: {
      "&  > * ": {
        marginBottom: "2.4rem",
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      <CustomFlex className={classes.sec1} direction="column" items="unset">
        <CustomDivider
          variant="custom"
          size="1px"
          color="background.darker"
          width="180%"
        />
        <CustomFlex margin="0 7.2rem 11rem 7.2rem">
          <CustomDivider
            variant="custom"
            cStyle={{ fontSize: "2.4rem", fontWeight: "500" }}
            content={`Merchant Business Information`}
            size="8.1rem"
            color="background.default"
            width="calc(100% + 14.4rem)"
            styles={{
              "& > *": {
                margin: "2rem 0 0 7.2rem",
              },
            }}
          />
        </CustomFlex>
        <CustomFlex
          flexWrap="wrap"
          justifyContent="space-between"
          margin="0 7.2rem 3.2rem 7.2rem"
          id="inputs"
          //   className={classes.sec2}
        >
          <InputField
            width="30.34%"
            name="businessLegalName"
            label="Business Legal Name"
            validate={validate.onlyReq}
          />
          <InputField
            width="30.34%"
            name="federalTaxID"
            label="Federal Tax ID"
            cformat={SSNFormat}
            validate={validate.ssn}
          />
          <InputField
            width="30.34%"
            name="businessAddress"
            label="Business Address"
            validate={validate.onlyReq}
          />
          <InputField
            width="30.34%"
            name="businessNameDBA"
            label="Business Name DBA"
            validate={validate.onlyReq}
          />
          <InputField
            width="30.34%"
            name="businessPhone"
            label="Business Phone"
            cformat={PhoneFormat}
            validate={validate.phoneNumber}
          />
          <CustomFlex width="30.34%">
            <InputField
              inputStyle={{
                borderRadius: ".4rem 0 0 .4rem",
                "& > *": { borderWidth: "1px 0 1px 1px" },
              }}
              width="60%"
              name="businesCity"
              label="City"
              validate={validate.onlyReq}
            />
            <CustomFlex width="22%" position="relative">
              <DropDown
                form={form}
                options={states}
                width="100"
                closeOnClick
                validate={validate.onlyReq}
                parentWidth="100%"
                name="businesState"
                label="State"
                value={contactState}
                inputStyle={{ borderRadius: "0" }}
                onSelect={(e) => {
                  setContactState(e);
                  form.change("businesState", e);
                }}
                filterBy={(each) => each}
                renderOption={(option) => {
                  return (
                    <CustomFlex
                      alignItems="start"
                      direction="column"
                      padding="1rem 0"
                    >
                      <Typography variant="h6" style={{ fontWeight: "600" }}>
                        {option}
                      </Typography>
                    </CustomFlex>
                  );
                }}
              />
            </CustomFlex>
            {/* <InputField
              inputStyle={{ borderRadius: "0" }}
              width="22%"
              name="businesState"
              label="State"
              validate={validate.onlyReq}
            /> */}
            <InputField
              inputStyle={{
                borderRadius: "0rem 0.4rem 0.4rem 0",
                "& > *": { borderWidth: "1px 1px 1px 0px" },
              }}
              width="22%"
              name="businesZip"
              label="Zip"
              type="number"
              validate={validate.onlyReq}
              cformat={ZipCodeFormat}
            />
          </CustomFlex>
          <CustomFlex width="30.34%" direction="column" position="relative">
            <DropDown
              form={form}
              options={[
                "Association/Estate/Trust",
                "Goverment",
                "Individual/Sole Proprietor",
                "Financial Institution",
                "LLC",
                "Non Profit/Tax Exempt (501c)",
                "Partnership",
                "Private Corporation",
                "Public Traded Corporation",
                "Sec Registered Entity",
              ]}
              width="100"
              // adjustment="2.6"
              closeOnClick
              label="Legal Entity *"
              value={entity}
              name="legalEntity"
              validate={validate.onlyReq}
              onSelect={(e) => {
                setEntity(e);
                form.change("legalEntity", e);
              }}
              filterBy={(each) => each}
              renderOption={(option) => {
                return (
                  <CustomFlex
                    alignItems="start"
                    direction="column"
                    padding="1rem 0"
                  >
                    <Typography variant="h6" style={{ fontWeight: "600" }}>
                      {option}
                    </Typography>
                  </CustomFlex>
                );
              }}
            />
          </CustomFlex>
        </CustomFlex>
      </CustomFlex>
      <CustomFlex
        flexWrap="wrap"
        justifyContent="space-between"
        margin="3.2rem 7.2rem 0 7.2rem"
        paddingBottom="3.1rem"
        className={classes.sec2}
      >
        <InputField
          width="30.34%"
          name="contactName"
          label="Contact Name"
          validate={validate.fullName}
        />
        <InputField
          width="30.34%"
          name="contactPhone"
          label="Contact Phone "
          validate={validate.phoneNumber}
          cformat={PhoneFormat}
        />
        {billingAddress === "Yes" ? (
          <InputField
            width="30.34%"
            name="billingAddress"
            label="Billing Address "
            validate={validate.onlyReq}
          />
        ) : null}
        <InputField
          width="30.34%"
          name="contactEmail"
          label="Contact Email"
          validate={validate.email}
        />
        <RadioInput
          content={["Yes", "No"]}
          title="Is the Billing address different from the Business address? "
          form={form}
          styles={{
            width: "30.34%",
            marginRight: `${billingAddress === "Yes" ? 0 : "34.83%"}`,
          }}
          validate={validate.onlyReq}
          setter={setBillingAddress}
        />
        {billingAddress === "Yes" ? (
          <CustomFlex width="30.34%">
            <InputField
              inputStyle={{
                borderRadius: ".4rem 0 0 .4rem",
                "& > *": { borderWidth: "1px 0 1px 1px" },
              }}
              width="60%"
              name="contactCity"
              label="City"
              validate={validate.onlyReq}
            />
            <CustomFlex width="22%" position="relative">
              <DropDown
                form={form}
                options={states}
                width="100"
                closeOnClick
                validate={validate.onlyReq}
                parentWidth="100%"
                name="contactState"
                label="State"
                value={contactState}
                inputStyle={{ borderRadius: "0" }}
                onSelect={(e) => {
                  setContactState(e);
                  form.change("contactState", e);
                }}
                filterBy={(each) => each}
                renderOption={(option) => {
                  return (
                    <CustomFlex
                      alignItems="start"
                      direction="column"
                      padding="1rem 0"
                    >
                      <Typography variant="h6" style={{ fontWeight: "600" }}>
                        {option}
                      </Typography>
                    </CustomFlex>
                  );
                }}
              />
            </CustomFlex>

            <InputField
              inputStyle={{
                borderRadius: "0rem 0.4rem 0.4rem 0",
                "& > *": { borderWidth: "1px 1px 1px 0px" },
              }}
              width="22%"
              name="contactZip"
              label="Zip"
              type="number"
              validate={validate.onlyReq}
            />
          </CustomFlex>
        ) : null}
        <CustomDivider
          variant="custom"
          size="1px"
          color="background.darker"
          width="180%"
        />
      </CustomFlex>

      <CustomFlex
        flexWrap="wrap"
        justifyContent="space-between"
        margin="3.2rem 7.2rem 0 7.2rem"
        paddingBottom="3.1rem"
        className={classes.sec2}
      >
        <InputField
          width="30.34%"
          name="ownerName"
          label="Owner/Officer/Principal Name *"
          validate={validate.fullName}
        />
        <InputField
          name="title"
          label="Title"
          width="30.4%"
          validate={validate.onlyReq}
        />
        <InputField
          width="30.34%"
          name="homeAddress"
          label="Home Address * "
          validate={validate.onlyReq}
        />
        <CustomFlex width="30.34%" justifyContent="space-between">
          <InputField
            width="44%"
            name="ownershipPercent"
            label="Ownership Percentage * "
            cformat={PercentageFormat}
            adorment="percent"
            validate={validate.onlyReq}
          />
          <InputField
            width="44%"
            name="dateOfBirth"
            label="Date of Birth *"
            placeholder="MM/DD/YYYY"
            cformat={DOBFormat}
            validate={validate.date}
          />
        </CustomFlex>
        <InputField
          width="30.34%"
          name="phoneNumber"
          label="Phone Number * "
          cformat={PhoneFormat}
          validate={validate.phoneNumber}
        />
        <CustomFlex width="30.34%">
          <InputField
            inputStyle={{
              borderRadius: ".4rem 0 0 .4rem",
              "& > *": { borderWidth: "1px 0 1px 1px" },
            }}
            width="60%"
            name="ownerCity"
            label="City"
            validate={validate.onlyReq}
          />
          <CustomFlex width="22%" position="relative">
            <DropDown
              form={form}
              options={states}
              width="100"
              closeOnClick
              validate={validate.onlyReq}
              parentWidth="100%"
              name="ownerState"
              label="State"
              value={contactState}
              inputStyle={{ borderRadius: "0" }}
              onSelect={(e) => {
                setContactState(e);
                form.change("ownerState", e);
              }}
              filterBy={(each) => each}
              renderOption={(option) => {
                return (
                  <CustomFlex
                    alignItems="start"
                    direction="column"
                    padding="1rem 0"
                  >
                    <Typography variant="h6" style={{ fontWeight: "600" }}>
                      {option}
                    </Typography>
                  </CustomFlex>
                );
              }}
            />
          </CustomFlex>

          <InputField
            inputStyle={{
              borderRadius: "0rem 0.4rem 0.4rem 0",
              "& > *": { borderWidth: "1px 1px 1px 0px" },
            }}
            width="22%"
            name="ownerZip"
            label="Zip"
            validate={validate.onlyReq}
          />
        </CustomFlex>
        <InputField
          width="30.34%"
          name="ssn"
          label="Social Security Number *"
          validate={validate.ssn}
          cformat={SSNFormat}
        />
        <RadioInput
          content={["Yes", "No"]}
          title="Is the Controller different from Owner? "
          form={form}
          styles={{
            width: "30.34%",
            marginRight: `${"34.83%"}`,
          }}
          validate={validate.onlyReq}
          setter={setControllerInfo}
        />
        {controllerInfo === "Yes" ? (
          <CustomDivider
            variant="custom"
            size="1px"
            color="background.darker"
            width="180%"
          />
        ) : null}
      </CustomFlex>
      {controllerInfo === "Yes" ? (
        <CustomFlex
          flexWrap="wrap"
          justifyContent="space-between"
          margin="3.2rem 7.2rem 0 7.2rem"
          paddingBottom="3.1rem"
          className={classes.sec2}
        >
          <InputField
            width="30.34%"
            name="controllerName"
            label="Controller Name *"
            validate={validate.fullName}
          />
          <InputField
            name="controllerTitle"
            label="Title"
            width="30.4%"
            validate={validate.onlyReq}
          />
          <InputField
            width="30.34%"
            name="controllerHomeAddress"
            label="Controller Home Address * "
            validate={validate.onlyReq}
          />
          <CustomFlex width="30.34%" justifyContent="space-between">
            <InputField
              width="44%"
              name="controllerOwnershipPercent"
              label="Ownership Percentage * "
              cformat={PercentageFormat}
              adorment="percent"
              validate={validate.onlyReq}
            />
            <InputField
              width="44%"
              name="controllerDateOfBirth"
              label="Date of Birth *"
              placeholder="MM/DD/YYYY"
              cformat={DOBFormat}
              validate={validate.date}
            />
          </CustomFlex>
          <InputField
            width="30.34%"
            name="controllerPhoneNumber"
            label="Phone Number * "
            cformat={PhoneFormat}
            validate={validate.phoneNumber}
          />
          <CustomFlex width="30.34%">
            <InputField
              inputStyle={{
                borderRadius: ".4rem 0 0 .4rem",
                "& > *": { borderWidth: "1px 0 1px 1px" },
              }}
              width="60%"
              name="controllerCity"
              label="City"
              validate={validate.onlyReq}
            />
            <CustomFlex width="22%" position="relative">
              <DropDown
                form={form}
                options={states}
                width="100"
                closeOnClick
                validate={validate.onlyReq}
                parentWidth="100%"
                name="controllerState"
                label="State"
                value={controllerState}
                inputStyle={{ borderRadius: "0" }}
                onSelect={(e) => {
                  setControllerState(e);
                  form.change("controllerState", e);
                }}
                filterBy={(each) => each}
                renderOption={(option) => {
                  return (
                    <CustomFlex
                      alignItems="start"
                      direction="column"
                      padding="1rem 0"
                    >
                      <Typography variant="h6" style={{ fontWeight: "600" }}>
                        {option}
                      </Typography>
                    </CustomFlex>
                  );
                }}
              />
            </CustomFlex>

            <InputField
              inputStyle={{
                borderRadius: "0rem 0.4rem 0.4rem 0",
                "& > *": { borderWidth: "1px 1px 1px 0px" },
              }}
              width="22%"
              name="controllerZip"
              label="Zip"
              validate={validate.onlyReq}
            />
          </CustomFlex>
          <InputField
            width="30.34%"
            name="controllerSSN"
            label="Social Security Number *"
            validate={validate.ssn}
            cformat={SSNFormat}
          />
        </CustomFlex>
      ) : null}
      <div style={{ height: "8.8rem" }}></div>
      <ButtonFooter form={form} counter={counter} />
    </>
  );
}
