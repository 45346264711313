import { Box } from "@mui/material";
import { Route, Routes } from "react-router";
import Iconic from "./client/components/iconic/Iconic";
import PaySafe from "./client/Pages/forms/paySafae/PaySafe";
import Shift from "./client/Pages/forms/shift/Shift";
import WorldPay from "./client/Pages/forms/worldPay/WorldPay";
import Home from "./client/Pages/home/Home";
import React from "react";
import { SuccessPage } from "./client/components/partials/succesPage";

function App() {
  const [leadInfo, setLeadInfo] = React.useState({});

  const Circle = ({ placement }) => {
    return (
      <div
        style={{
          position: "absolute",
          ...placement,
          // borderRadius: '50%',
          // width: '847.4px',
          // height: '945.3px',
          // border: '1.1px solid #05c806',
          zIndex: "1",
          animation: `spin 12s linear infinite `,
          transformOrigin: `50% 50%`,
        }}
      >
        <Iconic
          icon="DoubleCircle"
          viewBox="0 0 1150.645 1109.392"
          boxToSize={true}
        />
        <style>{`
					@keyframes spin {
						0% { transform: rotate(0deg); }
						100% { transform: rotate(360deg); }
					}
				`}</style>
      </div>
    );
  };

  return (
    <Box
      sx={{ bgcolor: "secondary.main" }}
      style={{
        fontSize: "2.50rem",
        height: "100vh",
        width: "100vw",
        position: "relative",
      }}
    >
      <Circle placement={{ top: "-50%", left: "-20%" }} />
      {/* <Circle placement={{ top: '-40%', left: '-25%' }} /> */}
      {/* <Circle placement={{ bottom: '-72.5%', right: '-20%' }} /> */}
      <Circle placement={{ bottom: "-60%", right: "-25%" }} />
      <Routes>
        <Route path="/reseller" element={<Home leadInfo={leadInfo} setLeadInfo={setLeadInfo} />} />
        <Route path="/reseller/wp" element={<WorldPay leadInfo={leadInfo} setLeadInfo={setLeadInfo} />} />
        <Route path="/reseller/ps" element={<PaySafe />} />
        <Route path="/reseller/s4" element={<Shift />} />
        <Route path='/reseller/success' element={<SuccessPage />} />
      </Routes>
    </Box>
  );
}

export default App;
