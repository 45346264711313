import { Button, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { CustomFlex } from "../../structure/customFlex";
import { useNavigate } from "react-router";

export default function SuccesPage() {
  const navigate = useNavigate();
  const useStyles = makeStyles((theme) => ({
    paper: {
      borderRadius: ".8rem",
      position: "absolute",
      padding: "6.4rem 13.3rem ",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "73.6rem",
      height: "43.2rem",
      zIndex: "100",
      [theme.breakpoints.down("sm")]: {
        width: "50rem",
        height: "55.6rem",
      },
    },
  }));
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <CustomFlex
        direction="column"
        justifyContent="space-between"
        height="100%"
      >
        <Typography
          color="primary.main"
          sx={{ fontSize: "3rem", fontWeight: "500" }}
        >
          Success!
        </Typography>
        <Typography
          color="secondary.main"
          sx={{
            fontSize: "1.8rem",
            textAlign: "center",
            paddingBottom: "5rem",
          }}
        >
          The form as been successfully submitted. The merchant will recive a
          email to sign the MPA.
          <br />
          <br />
          Once the MPA is signed, all parties will recive a copy via email.
        </Typography>

        <Button
          variant="contained"
          sx={{ width: "31.3rem" }}
          onClick={() => navigate("/reseller")}
        >
          Ok, I got it
        </Button>
      </CustomFlex>
    </Paper>
  );
}
