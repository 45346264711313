const validate = {
    onlyReq: (value) => {
        return value && value !== "" ? undefined : true;
    },
    accNum: (value) => {
        return value &&
            value !== "" &&
            value.split("").length >= 10 &&
            value.split("").length <= 12
            ? undefined
            : "ACC number must be between 10 and 12 digits";
    },
    routeNum: (value) => {
        return value && value !== "" && value.split("").length === 9
            ? undefined
            : "Routing number must have 9 digits";
    },
    onlyReqIfField: (value, name, eValue, form) => {
        return form.getFieldState(name)?.value === eValue && value && value !== ""
            ? undefined
            : true;
    },
    onlyReqIf: (value, conditionalvalue, mesg) => {
        return value && value !== "" && conditionalvalue ? undefined : mesg ? mesg : true;
    },
    repNum: (value) => {
        return value &&
            value.split("").length >= 9 &&
            value.split("").length <= 16
            ? undefined
            : "Rep ID must be between 9 and 16 characters";
    },
    checkBox: (value, error) => {
        if (value && value.length !== 0) {
            if (error) {
                error(false)
            }
            return undefined
        } else {
            if (error) {
                error(true)
            }

            return true

        }
    },
    ssn: (value) => {
        return value &&
            value !== "" &&
            value.split("").length === 9
            ? undefined
            : "SSN must be 9 digits";
    },
    phoneNumber: (value) => {
        return value &&
            value !== "" &&
            value.split("").length === 10
            ? undefined
            : "Phone number must be 10 digits";
    },
    date: (value) => {

        let date = value?.split("")
        date?.splice(2, 0, '-')
        date?.splice(5, 0, '-')
        return value && (new Date(date?.join("")) !== "Invalid Date") && !isNaN(new Date(date?.join(""))) ? undefined : 'Please type a valid date'

    },
    fullName: (value) => {
        return value &&
            value !== "" &&
            value.split("").length >= 3 &&
            value.split("").length <= 30 &&
            value.split(" ").length >= 2
            ? undefined
            : true;
    },
    email: (value) => {
        return value &&
            value !== "" &&
            value.split("").length >= 5 &&
            value.split("").length <= 30 &&
            value.includes("@") &&
            value.includes(".")
            ? undefined
            : true;
    }
};
export default validate;