import { Button, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useNavigate } from "react-router";
import { CustomFlex } from "../customFlex";

const Buttonfooter = ({ form, counter, disabled = false }) => {
  const useStyles = makeStyles((theme) => ({
    footer: {
      zIndex: "5000",
      height: "8.8rem",
      position: "sticky",
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      marginTop: ".2rem",
      padding: "0 7.2rem",
      alignItems: "center",
      boxShadow: `0 -5px 12px -7px ${theme.palette.background.shadow}`,
      bottom: "0",
      // top: "calc(90vh - 3rem)",
    },
  }));
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Paper className={classes.footer}>
      <CustomFlex justifyContent="space-between" width="100%">
        {/* {submitError && <Alert severity="error">{submitError}</Alert>} */}
        <CustomFlex>
          <Button
            variant="outlined"
            // color="secondary"
            sx={{
              color: "text.primary",
              borderColor: "text.primary",
              "&:hover": {
                color: "text.primary",
                borderColor: "text.primary",
                boxShadow: "1px 3px 6px 0 rgba(0, 0, 0, 0.16)",
              },
            }}
            disabled={false}
            onClick={() => navigate("/reseller")}
            style={{ marginLeft: "1.6rem" }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            // color="secondary"
            sx={{
              color: "text.primary",
              borderColor: "text.primary",
              "&:hover": {
                color: "text.primary",
                borderColor: "text.primary",
                boxShadow: "1px 3px 6px 0 rgba(0, 0, 0, 0.16)",
              },
            }}
            onClick={() => {
              return counter.pageCounter > 1
                ? (counter.setPageCounter(counter.pageCounter - 1),
                  console.log(form.getState().values))
                : null;
            }}
            style={{ marginLeft: "1.6rem" }}
          >
            back
          </Button>
        </CustomFlex>
        <CustomFlex>
          <Button
            variant="contained"
            disabled={false}
            color="primary"
            onClick={() => {
              counter.pageCounter === 4
                ? navigate("/reseller/success")
                : console.log("");

              return form.submit() !== undefined && counter.pageCounter <= 4
                ? (counter.setPageCounter(counter.pageCounter + 1),
                  console.log(form.getState().values),
                  counter.pageCounter === 4
                    ? navigate("/reseller/success")
                    : console.log(""))
                : null;
            }}
            // type={counter.pageCounter === 5 ? "submit" : "button"}
            type="submit"
            // disabled={!values.lineitems.length || submitting || pristine}
            style={{ marginLeft: "1rem", width: "20.8rem" }}
          >
            {counter.pageCounter === 4 ? "Submit" : "Next"}
          </Button>
        </CustomFlex>
      </CustomFlex>
    </Paper>
  );
};

export default Buttonfooter;
