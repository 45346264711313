import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import { MoneyFormat, PercentageFormat } from "../../../../../../utils";
import { DatePicker } from "../../../../../components/inputs/datePicker";
import InputField from "../../../../../components/inputs/inputField/InputField";
import { RadioInput } from "../../../../../components/inputs/radioInputs";
import { CustomDivider } from "../../../../../components/partials/customDividers";
import { ButtonFooter } from "../../../../../components/structure/buttomFooter";
import { CustomFlex } from "../../../../../components/structure/customFlex";
import { HeaderContainer } from "../../../../../components/structure/headerContainer";
import { validate } from "../../../utils";
// import { MoneyFormat, PercentageFormat } from "../../../@utils";

const SecondPage = ({ form, counter }) => {
  // const [next, setNext] = React.useState(true);

  const useStyles = makeStyles((theme) => ({
    percentages: {
      //   borderBottom: `1px solid ${theme.palette.background.darker}`,
      "& #sec1": {
        borderRight: `1px solid ${theme.palette.background.darker}`,
        padding: "2.4rem 0 0 0 ",

        "& > div": {
          marginBottom: "2.4rem",
        },
      },
      "& #sec2": {
        padding: "1.6rem 3.2rem 0 3.2rem",
        borderRight: `1px solid ${theme.palette.background.darker}`,
        "& #fields": {
          border: `0.25px solid ${theme.palette.text.lightText}`,
          borderRadius: ".6rem",
          padding: ".8rem 1.6rem 1.5rem 1.6rem",

          "& #top": {
            marginBottom: "2.4rem",
          },
        },
      },
      "& #sec3": {
        padding: "2.4rem 0 0 0 ",
        "& > div > div": {
          marginBottom: "2.4rem",
        },
      },
    },
    banking: {
      paddingTop: "2.4rem",
    },
  }));
  const classes = useStyles();

  return (
    <>
      <CustomFlex direction="column" margin="0 7.2rem 0 7.2rem">
        <HeaderContainer header="WorldPay Merchant Processing Application" />
        <CustomFlex justifyContent="start" width="100%" marginBottom="4.8rem">
          <InputField
            name="repID"
            label="Sales Representative ID Number"
            // type="number"
            validate={validate.repNum}
            width="20%"
            minWidth="15rem"
          />
        </CustomFlex>
      </CustomFlex>
      <CustomFlex margin="0 7.2rem 8.1rem 7.2rem">
        <CustomDivider
          variant="custom"
          cStyle={{ fontSize: "2.4rem", fontWeight: "500" }}
          content="Business Profiles and Assumptions"
          size="8.1rem"
          color="background.default"
          width="calc(100% + 14.4rem)"
          styles={{
            "& > *": {
              margin: "2rem 0 0 7.2rem",
            },
          }}
        />
      </CustomFlex>
      <CustomFlex
        alignItems="start"
        justifyContent="start"
        className={classes.percentages}
        height="fit-content"
        margin="0 7.2rem 0 7.2rem"

        // paddingTop="2.4rem"
      >
        <CustomDivider
          variant="custom"
          size="1px"
          color="background.darker"
          width="180%"
        />

        <Grid container>
          <Grid item xs={12} md={6} lg={4}>
            <CustomFlex
              id="sec1"
              direction="column"
              // width="23.9%"
              paddingRight="4.8rem"
            >
              <InputField
                name="annualVolume"
                validate={validate.onlyReq}
                label="Annual Volume *"
                cformat={MoneyFormat}
                adorment="dollar"
              />
              <InputField
                validate={validate.onlyReq}
                name="averageTicket"
                label="Average Ticket *"
                cformat={MoneyFormat}
                adorment="dollar"
              />
              <InputField
                validate={validate.onlyReq}
                name="highestTicket"
                label="Highest Ticket *"
                cformat={MoneyFormat}
                adorment="dollar"
              />
            </CustomFlex>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            <CustomFlex
              direction="column"
              // width="27.25%"
              justifyContent="start"
              id="sec2"
            >
              <CustomFlex id="fields" direction="column" width="100%">
                <InputField
                  validate={validate.onlyReq}
                  id="top"
                  name="cardPresent"
                  label="Card Present *"
                  cformat={PercentageFormat}
                  adorment="percent"
                  onChange={(e) => {
                    form.change("cardNotPresent", 100 - Number(e.target.value));
                    form.change("cardPresent", Number(e.target.value));
                  }}
                />
                <InputField
                  validate={validate.onlyReq}
                  name="cardNotPresent"
                  label="Card Not Present *"
                  cformat={PercentageFormat}
                  adorment="percent"
                  onChange={(e) => {
                    form.change("cardNotPresent", Number(e.target.value));
                    form.change("cardPresent", 100 - Number(e.target.value));
                  }}
                  // id="cNP"
                />
              </CustomFlex>
              <Box
                sx={{
                  borderRight: "0.5px solid",
                  borderRadius: "0 !important",
                  borderColor: "text.lightText",
                  padding: "0 !important",
                  height: "3.5rem",
                }}
              />
              <Typography
                sx={{ fontSize: "1.5rem", margin: "1rem 0 2.1rem 0" }}
              >
                Sum of percentages must equal 100%.
              </Typography>
            </CustomFlex>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <CustomFlex
              alignItems="start"
              id="sec3"
              paddingLeft="4.8rem"
              justifyContent="space-between"
              // width="48.85%"
            >
              <CustomFlex direction="column" width="45.06%">
                {/* <InputField
                  validate={validate.onlyReq}
                  name="moto"
                  label="Moto *"
                  cformat={PercentageFormat}
                  adorment="percent"
                />
                <InputField
                  validate={validate.onlyReq}
                  name="internet"
                  label="Internet *"
                  cformat={PercentageFormat}
                  adorment="percent"
                /> */}
                <InputField
                  validate={validate.onlyReq}
                  name="international"
                  label="International *"
                  cformat={PercentageFormat}
                  adorment="percent"
                />
              </CustomFlex>
              <CustomFlex direction="column" width="45.06%">
                {/* <InputField
                  validate={validate.onlyReq}
                  name="cardSwipe"
                  label="Card Swipe *"
                  cformat={PercentageFormat}
                  adorment="percent"
                /> */}
                <InputField
                  validate={validate.onlyReq}
                  name="b2b"
                  label="B2B *"
                  cformat={PercentageFormat}
                  adorment="percent"
                />
              </CustomFlex>
            </CustomFlex>
          </Grid>
        </Grid>
      </CustomFlex>

      <CustomFlex
        margin="0 7.2rem 0 7.2rem"
        justifyContent="start"
        className={classes.banking}
        alignItems="baseline"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <CustomFlex width="51rem" direction="column" alignItems="baseline">
              <CustomFlex
                width="100%"
                justifyContent="space-between"
                marginBottom="2.4rem"
              >
                <CustomFlex>
                  <DatePicker
                    validate={validate.onlyReq}
                    name="openDate"
                    label="Open Date *"
                    form={form}
                  />
                </CustomFlex>
                <RadioInput
                  validate={validate.onlyReq}
                  content={["Yes", "No"]}
                  title="Seasonal Sales"
                  form={form}
                />
              </CustomFlex>
              <InputField
                validate={validate.onlyReq}
                name="typeOfGoods"
                label="Type of Goods"
                width="100%"
              />
            </CustomFlex>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <CustomFlex paddingLeft="6.4rem">
              <RadioInput
                validate={validate.onlyReq}
                title="Refund Policy"
                content={[
                  "No Refund",
                  "Refund in 30 days or less",
                  "Merchandise Exchange Only",
                  "Other",
                ]}
                column
                form={form}
              />
            </CustomFlex>
          </Grid>
        </Grid>
      </CustomFlex>
      <div style={{ height: "8.8rem" }}></div>
      <ButtonFooter form={form} counter={counter} />
    </>
  );
};

export default SecondPage;
