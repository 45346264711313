import NumberFormat from 'react-number-format'

function PercentageFormat(props) {
	const { inputRef, onChange, ...other } = props

	return (
		<NumberFormat
			{...other}
			defaultValue={props.defaultValue}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				})
			}}
			thousandSeparator
			allowLeadingZeros={false}
			allowNegative={false}
			isNumericString
			decimalScale="2"
			isAllowed={(values) => { return values.value <= 100 ? true : false }}
		// suffix="%"
		/>
	)
}

export default PercentageFormat
