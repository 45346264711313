import axios from 'axios'
import qs from 'qs'
var origin = window.location.protocol + "//" + window.location.host;


const worldPayTemplate = async (users, values) => {

    const checkBox = (val, limit) => {

        if (val && val.length >= limit) {
            return 'both'
        } else {
            return val.find(item => item === 'Digital') ? 'Digital' : 'Physical'
        }

    }



    let authdata = qs.stringify({
        'assertion': 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJkM2U0YzI5Yi0wNmNlLTQ5ODQtOWQxYi0xNDIwZWQ0ODQ5NTgiLCJzdWIiOiI0NmExMmE1MC02ZDdjLTQ5MTktYTYwZi02MGMyZDE3MDc1NGMiLCJhdWQiOiJhY2NvdW50LmRvY3VzaWduLmNvbSIsImlhdCI6MTY0NjI0NzQzNSwiZXhwIjoyMDAwMDAwMDAwLCJzY29wZSI6InNpZ25hdHVyZSJ9.KrrE5EIh_uYZea1UlReD8u6KlwXo_Yv2TrSWFR_yWW1OW37qPfgyfPTZnKwuYrbg53PuExoJsUjQk3-baFfFtmbQ4-jOafPNmGAvSfDi18tFqqiWkEIWca2ESpy5WDnOlsH55WBc-0Jz035uzhoxgMirSX_jbo9o4laZe4bNwGnWWApkI3tFMVVZmqu3bmhySWOaKyrQzSY0GTa51JtZsjZTKski2zOEi8FKplYGHvunIn6gweteN9Qx8rLqe1eN0nfqtmqWz8NA2rMxt_IjLxbLKFaPxifwVLUcbvc2iKbfkVsodwuece07Dmo7W5RWaxhp-p8c-bQ-kmJ7mblO3w',
        'grant_type': 'urn:ietf:params:oauth:grant-type:jwt-bearer'
    });
    let authConfig = {
        method: 'post',
        url: 'https://request-handeller.herokuapp.com/https://account.docusign.com/oauth/token',
        headers: {
            'Authorization': 'Basic ZDNlNGMyOWItMDZjZS00OTg0LTlkMWItMTQyMGVkNDg0OTU4Og==',
            'Content-Type': 'application/x-www-form-urlencoded',

        },
        timeout: 1000,
        maxRedirects: 0,
        data: authdata
    };

    axios(authConfig)
        .then((response) => {
            console.log((response.data.access_token));
            var data = JSON.stringify({
                "emailSubject": "Ireland Pay - WorldPay Application",
                "templateId": "1bbde4da-eb3e-4214-9087-8a3db86b0692",
                "templateRoles": [
                    {
                        "email": users[0].reseller ? users[0].reseller.email : users[1].reseller.email,
                        "name": users[0].reseller ? users[0].reseller.name : users[1].reseller.name,
                        "roleName": "Reseller",
                        "tabs": {
                            "textTabs": [{
                                "value": values.repID,
                                "tabLabel": "repID"
                            },
                            {
                                "value": values.annualVolume,
                                "tabLabel": "annualVolume",
                            },
                            {
                                "value": values.averageTicket,
                                "tabLabel": "averageTicket",
                            },
                            {
                                "value": values.highestTicket,
                                "tabLabel": "highestTicket",
                            },
                            {
                                "value": values.cardPresent,
                                "tabLabel": "cardPresent",
                            },
                            {
                                "value": values.cardNotPresent,
                                "tabLabel": "cardNotPresent",
                            },
                            {
                                "value": '10',
                                "tabLabel": "cardSwipe",
                            },
                            {
                                "value": '90',
                                "tabLabel": "moto",
                            },
                            {
                                "value": '0',
                                "tabLabel": "internet",
                            },
                            {
                                "value": values.b2b,
                                "tabLabel": "b2b",
                            },
                            {
                                "value": values.international,
                                "tabLabel": "international",
                            },
                            {
                                "value": '3.86',
                                "tabLabel": "flatRateDiscountRate1",
                            },
                            {
                                "value": '0',
                                "tabLabel": "flatRateTransactionFee1",
                            },
                            {
                                "value": values.tieredPricingQualifiedDiscountRate,
                                "tabLabel": "tieredPricingQualifiedDiscountRate",
                            },
                            {
                                "value": values.tieredPricingQualifiedTransactionFee,
                                "tabLabel": "tieredPricingQualifiedTransactionFee",
                            },
                            {
                                "value": values.tieredPricingMidQualifiedDiscountRate,
                                "tabLabel": "tieredPricingMidQualifiedDiscountRate",
                            },
                            {
                                "value": values.tieredPricingMidQualifiedTransactionFee,
                                "tabLabel": "tieredPricingMidQualifiedTransactionFee",
                            },
                            {
                                "value": values.tieredPricingNonQualifiedDiscountRate,
                                "tabLabel": "tieredPricingNonQualifiedDiscountRate",
                            },
                            {
                                "value": values.tieredPricingNonQualifiedTransactionFee,
                                "tabLabel": "tieredPricingNonQualifiedTransactionFee",
                            },
                            {
                                "value": values.interchangeDiscountRate1,
                                "tabLabel": "interchangeDiscountRate1",
                            },
                            {
                                "value": values.interchangeTransactionFee1,
                                "tabLabel": "interchangeTransactionFee1",
                            },
                            {
                                "value": values.amexNumber,
                                "tabLabel": "amexNumb",
                            },
                            {
                                "value": values.pinDebitDiscountRate1,
                                "tabLabel": "pinDebitDiscountRate1",
                            },
                            {
                                "value": values.pinDebitMonthlyHostingFee1,
                                "tabLabel": "pinDebitMonthlyHostingFee1",
                            },
                            {
                                "value": values.pinDebitTransactionFee1,
                                "tabLabel": "pinDebitTransactionFee1",
                            },
                            {
                                "value": '0',
                                "tabLabel": "onFileFee",
                            },
                            {
                                "value": '0',
                                "tabLabel": "batchFee",
                            },
                            {
                                "value": '0',
                                "tabLabel": "voiceDial",
                            },
                            {
                                "value": '15',
                                "tabLabel": "retrivalFee",
                            },
                            {
                                "value": '0',
                                "tabLabel": "minimunBill",
                            },
                            {
                                "value": '0',
                                "tabLabel": "earlyDeconFee",
                            },
                            {
                                "value": '15',
                                "tabLabel": "chargebackFee",
                            },
                            {
                                "value": values.equipment1,
                                "tabLabel": "equipment1",
                            },
                            {
                                "value": values.equipment2,
                                "tabLabel": "equipment2",
                            },
                            {
                                "value": values.equipment3,
                                "tabLabel": "equipment3",
                            },
                            {
                                "value": values.qty1,
                                "tabLabel": "qty1",
                            },
                            {
                                "value": values.qty2,
                                "tabLabel": "qty2",
                            },
                            {
                                "value": values.qty3,
                                "tabLabel": "qty3",
                            }
                            ],
                            "radioGroupTabs": [{
                                "groupName": "legalEntity",
                                "radios": [{
                                    "selected": true,
                                    "value": values.legalEntity
                                }]
                            },
                            {
                                "groupName": "pricing",
                                "radios": [{
                                    "selected": true,
                                    "value": 'Flat Rate'
                                }]
                            },
                            {
                                "groupName": "amexNum",
                                "radios": [{
                                    "selected": true,
                                    "value": "No"
                                }]
                            },
                            {
                                "groupName": "pinDebitPricing",
                                "radios": [{
                                    "selected": true,
                                    "value": "No"
                                }]
                            },
                            {
                                'groupName': 'goodsandserviceschargedtocreditcardon',
                                'radios': [{
                                    'selected': true,
                                    'value': values.goodsandserviceschargedtocreditcardon
                                }]
                            },
                            {
                                "groupName": "aregoodsandservicesdelivered",
                                "radios": [{
                                    "selected": true,
                                    "value": checkBox(values.aregoodsandservicesdelivered, 2)
                                }]
                            },
                            {
                                "groupName": "isafulfillmenthouseused?",
                                "radios": [{
                                    "selected": checkBox(values.aregoodsandservicesdelivered, 2) === "Physical" ? true : false,
                                    "value": checkBox(values.aregoodsandservicesdelivered, 2) === "Physical" ? values.isafulfillmenthouseused : "No"
                                }]
                            },
                            {
                                "groupName": "isthefulfillmenthousePCIDDSCompliant?",
                                "radios": [{
                                    "selected": values.isafulfillmenthouseused === "Yes" ? true : false,
                                    "value": values.isafulfillmenthouseused === "Yes" ? values.isthefulfillmenthousePCIDDSCompliant : "No"
                                }]
                            },
                            {
                                "groupName": "locationType",
                                "radios": [{
                                    "selected": values.isafulfillmenthouseused === "Yes" ? true : false,
                                    "value": values.isafulfillmenthouseused === "Yes" ? values.locationType : "Retail Store"
                                }]
                            }
                            ]
                        }
                    },
                    {
                        "email": values.contactEmail,
                        "name": values.contactName,
                        "roleName": "Merchant",
                        "tabs": {
                            "textTabs": [{
                                "value": values.businessLegalName,
                                "tabLabel": "businessLegalName"
                            }, {
                                "value": values.businessNameDBA,
                                "tabLabel": "businessNameDBA"
                            },
                            {
                                "value": values.openDate,
                                "tabLabel": "openDate",
                            },
                            {
                                "value": values.businessAddress,
                                "tabLabel": "businessAddress",
                            },
                            {
                                "value": `${values.businesCity}, ${values.businesState}, ${values.businesZip}`,
                                "tabLabel": "businessCityStateZip",
                            },
                            {
                                "value": values.businessPhone,
                                "tabLabel": "businessPhone",
                            },
                            {
                                "value": values.federalTaxID,
                                "tabLabel": "federalTaxID",
                            },
                            {
                                "value": values.title,
                                "tabLabel": "title",
                            },
                            {
                                "value": values.istheControllerdifferentfromOwner === "yes" ? values.controllerName : values.ownerName,
                                "tabLabel": "controllerName",
                            },
                            {
                                "value": values.istheControllerdifferentfromOwner === "yes" ? values.controllerTitle : values.title,
                                "tabLabel": "controllerTitle",
                            },
                            {
                                "value": values.istheControllerdifferentfromOwner === "yes" ? values.controllerDateOfBirth : values.dateOfBirth,
                                "tabLabel": "controllerDateOfBirth",
                            },
                            {
                                "value": values.istheControllerdifferentfromOwner === "yes" ? values.controllerSSN : values.ssn,
                                "tabLabel": "controllerSSN",
                            },
                            {
                                "value": values.istheControllerdifferentfromOwner === "yes" ? values.controllerOwnershipPercent : values.ownershipPercent,
                                "tabLabel": "controllerOwnershipPercent",
                            },
                            {
                                "value": values.istheControllerdifferentfromOwner === "yes" ? values.controllerHomeAddress : values.homeAddress,
                                "tabLabel": "controllerHomeAddress",
                            },
                            {
                                "value": values.istheControllerdifferentfromOwner === "yes" ? `${values.controllerCity}, ${values.controllerState}, ${values.controllerZip}` : `${values.city}, ${values.state}, ${values.zip}`,
                                "tabLabel": 'controllerCityStateZip',
                            },
                            {
                                "value": values.controllerPhoneNumber,
                                "tabLabel": "controllerPhoneNumber",
                            },
                            {
                                "value": values.contactName,
                                "tabLabel": "contactName",
                            },
                            {
                                "value": values.contactEmail,
                                "tabLabel": "contactEmail",
                            },
                            {
                                "value": values.billingAddress,
                                "tabLabel": "billingAddress",
                            },
                            {
                                "value": `${values.contactCity ? values.contactCity : " "}, ${values.contactState ? values.contactState : " "}, ${values.contactZip ? values.contactZip : " "}`,
                                "tabLabel": "billingCityStateZip",
                            },
                            {
                                "value": values.billingPhone,
                                "tabLabel": "billingPhone",
                            },
                            {
                                "value": values.ownerName,
                                "tabLabel": "ownerName",
                            },
                            {
                                "value": values.dateOfBirth,
                                "tabLabel": "dateOfBirth",
                            },
                            {
                                "value": values.ssn,
                                "tabLabel": "ssn",
                            },
                            {
                                "value": values.ownershipPercent,
                                "tabLabel": "ownershipPercent",
                            },
                            {
                                "value": values.homeAddress,
                                "tabLabel": "homeAddress",
                            },
                            {
                                "value": `${values.ownerCity}, ${values.ownerState}, ${values.ownerZip}`,
                                "tabLabel": "homeCityStateZip",
                            },
                            {
                                "value": values.phoneNumber,
                                "tabLabel": "phoneNumber",
                            },
                            {
                                "value": values.typeOfGoods,
                                "tabLabel": "typeOfGoods",
                            },
                            {
                                "value": values.routingNumber,
                                "tabLabel": "routingNumber",
                            },
                            {
                                "value": values.accountNumber,
                                "tabLabel": "accountNumber",
                            }
                            ],
                            "radioGroupTabs": [{
                                "groupName": "refundPolicy",
                                "radios": [{
                                    "selected": true,
                                    "value": values.refundPolicy
                                }]
                            },
                            {
                                "groupName": "seasonalSales",
                                "radios": [{
                                    "selected": true,
                                    "value": values.seasonalSales
                                }]
                            },
                            {
                                "groupName": "accountType",
                                "radios": [{
                                    "selected": true,
                                    "value": values.dDAAccountType
                                }]
                            },
                            {
                                "groupName": "billingFrequency",
                                "radios": [{
                                    "selected": true,
                                    "value": values.billingFrequency
                                },]
                            },
                            {
                                "groupName": "bussinessType",
                                "radios": [{
                                    "selected": true,
                                    "value": values.bussinessType
                                }]
                            }
                            ]
                        }
                    }
                ],
                "status": "sent"
            });

            var config = {
                method: 'post',
                url: 'https://request-handeller.herokuapp.com/https://na4.docusign.net/restapi/v2.1/accounts/2f8dec5a-ac21-46ea-af7c-59cc1cfca658/envelopes',
                headers: {
                    'Authorization': `Bearer ${response.data.access_token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                    console.log(error);
                    return { err: 'document was not sent', errInfo: error }
                });


        })
        .catch((error) => {
            console.log(error);
            return { err: 'document was not sent', errInfo: error }
        });


}




export { worldPayTemplate }
