import { Button, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

import React from "react";
import { Form } from "react-final-form";
import { worldPayTemplate } from "../../../../server/docusign";
import { leadCreator } from "../../../../server/irisCrm";
import { ButtonFooter } from "../../../components/structure/buttomFooter";
import { CustomFlex } from "../../../components/structure/customFlex";
import { FirstPage, ForthPage, SecondPage, ThirdPage } from "./components";

const Worldpay = ({ leadInfo, setLeadInfo }) => {
  const [pageCounter, setPageCounter] = React.useState(1);

  // console.log(leadInfo);
  const useStyles = makeStyles((theme) => ({
    paper: {
      borderRadius: ".8rem .8rem 0 0",
      position: "absolute",
      // padding: "0 7.2rem 0 7.2rem",
      bottom: "0",
      left: "50%",
      transform: "translate(-50%, 0%)",
      width: "84.0%",
      height: "calc(100% - 3rem)",
      zIndex: "100",
      overflowX: "hidden",
    },
    footer: {
      zIndex: "5000",
      height: "8.8rem",
      position: "absolute",
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      marginTop: ".2rem",
      padding: "0 7.2rem",
      alignItems: "center",
      boxShadow: `0 -5px 12px -7px ${theme.palette.background.shadow}`,
      bottom: "0",
    },
  }));
  const classes = useStyles();
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const onSubmit = async (values) => {
    await sleep(300);
    console.log("submitted", values);
    if (pageCounter > 3) {
      leadCreator("worldpay", leadInfo, values);
      console.log("success");
      worldPayTemplate(leadInfo, values);
    } else {
      console.log("validaiting");
    }
  };

  return (
    <Paper className={classes.paper}>
      <Paper
        sx={{
          height: "fit-content",
          position: "relative",
          minHeight: "calc(100vh - 3rem)",
        }}
      >
        <Form
          subscription={{ handeleSubmit: true }}
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form
              onSubmit={handleSubmit}
              // style={{ minHeight: "calc(100vh - 3rem)" }}
            >
              {pageCounter === 1 ? (
                <FirstPage
                  form={form}
                  counter={{ pageCounter, setPageCounter }}
                />
              ) : pageCounter === 2 ? (
                <SecondPage
                  form={form}
                  counter={{ pageCounter, setPageCounter }}
                />
              ) : pageCounter === 3 ? (
                <ThirdPage
                  form={form}
                  counter={{ pageCounter, setPageCounter }}
                />
              ) : pageCounter === 4 || pageCounter === 5 ? (
                <ForthPage
                  form={form}
                  counter={{ pageCounter, setPageCounter }}
                />
              ) : null}
            </form>
          )}
        />
      </Paper>
    </Paper>
  );
};

export default Worldpay;
