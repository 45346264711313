import React from "react";
import { RadioInput } from "../../../../../components/inputs/radioInputs";
import { SectionInput } from "../../../../../components/inputs/sectionInput";
import { CustomDivider } from "../../../../../components/partials/customDividers";
import { CustomFlex } from "../../../../../components/structure/customFlex";
import InputField from "../../../../../components/inputs/inputField/InputField";
import { makeStyles } from "@mui/styles";
import Radioinput from "../../../../../components/inputs/radioInputs/RadioInput";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { validate } from "../../../utils";
import { CheckboxInput } from "../../../../../components/inputs/checkboxInput";
import { ButtonFooter } from "../../../../../components/structure/buttomFooter";
import {
  ACHNumbers,
  NumberFormat,
  SNumberFormat,
} from "../../../../../../utils";

const ForthPage = ({ form, counter }) => {
  const [goodsType, setGoodsType] = React.useState(null);
  const [fullfilmentHouse, setFullfilmentHouse] = React.useState(null);

  const useStyles = makeStyles((theme) => ({
    sec1: {
      "& > div > div #rigth": {
        marginLeft: "3.6rem",
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      <CustomFlex direction="column" className={classes.sec1} items="unset">
        <CustomFlex margin="0 7.2rem 11rem 7.2rem">
          <CustomDivider
            variant="custom"
            cStyle={{ fontSize: "2.4rem", fontWeight: "500" }}
            content={`Merchant Bank Account Information`}
            size="8.1rem"
            color="background.default"
            width="calc(100% + 14.4rem)"
            styles={{
              "& > *": {
                margin: "2rem 0 0 7.2rem",
              },
            }}
          />
        </CustomFlex>
        <CustomFlex
          direction="column"
          items="flex-start"
          margin="0 7.2rem 0 7.2rem"
          width="100%"
        >
          <CustomFlex>
            <RadioInput
              validate={validate.onlyReq}
              content={["Checking", "Savings"]}
              title="DDA Account Type"
              form={form}
            />
          </CustomFlex>
          <CustomFlex
            margin="2.3rem 0 4.8rem 0"
            width="100%"
            justifyContent="start"
            items="start"
          >
            <InputField
              validate={validate.routeNum}
              name="routingNumber"
              width="20%"
              label="Routing Number"
              cformat={ACHNumbers}
            />
            <InputField
              validate={validate.accNum}
              width="25%"
              name="accountNumber"
              label="Account Number"
              id="rigth"
              cformat={ACHNumbers}
            />
          </CustomFlex>
        </CustomFlex>
      </CustomFlex>
      <CustomFlex width="100%" items="unset" direction="column">
        <CustomFlex margin="0 7.2rem 11.1rem 7.2rem">
          <CustomDivider
            variant="custom"
            cStyle={{ fontSize: "2.4rem", fontWeight: "500" }}
            content={`Equipment and Goods Information`}
            size="8.1rem"
            color="background.default"
            width="calc(100% + 14.4rem)"
            styles={{
              "& > *": {
                margin: "2rem 0 0 7.2rem",
              },
            }}
          />
        </CustomFlex>
        <CustomFlex
          justify-content="start"
          margin="0 7.2rem 3.2rem 7.2rem"
          items="flex-start"
        >
          <CustomFlex items="flex-Start">
            <Radioinput
              validate={validate.onlyReq}
              content={["Order", "Shipment"]}
              title="Goods and services charged to credit card on *"
              form={form}
              errorText="Please select an option"
            />
            <CheckboxInput
              validate={validate.checkBox}
              styles={{ marginLeft: "3.4rem" }}
              content={["Digital", "Physical"]}
              title="Are goods and services delivered *"
              form={form}
              errorText="Please select at least one option"
              setter={setGoodsType}
            />
          </CustomFlex>
          <CustomFlex direction="column" items="flex-start" marginLeft="3.4rem">
            {goodsType === "Physical" ? (
              <Radioinput
                validate={validate.onlyReq}
                content={["Yes", "No"]}
                title="Is a fulfillment house used? *"
                form={form}
                setter={setFullfilmentHouse}
                styles={{ marginBottom: "4.8rem" }}
              />
            ) : null}
            {fullfilmentHouse === "Yes" ? (
              <Radioinput
                validate={validate.onlyReq}
                content={["Yes", "No"]}
                title="Is the fulfillment house PCI DDS Compliant? *"
                form={form}
              />
            ) : null}
          </CustomFlex>
          <CustomFlex>
            {fullfilmentHouse === "Yes" ? (
              <Radioinput
                validate={validate.onlyReq}
                content={[
                  "Retail Store Front",
                  "Office Building",
                  "Residence",
                  "Industrial Building",
                  "Trade Show",
                ]}
                title="Location Type *"
                form={form}
                column
                styles={{ marginLeft: "5.4rem" }}
              />
            ) : null}
          </CustomFlex>
        </CustomFlex>
        <CustomDivider
          variant="custom"
          size="1px"
          color="background.darker"
          width="180%"
        />
      </CustomFlex>
      <CustomFlex direction="column" items="flex-start" margin="3.2rem 7.2rem">
        <CustomFlex width="29.35%" justifyContent="space-between">
          <InputField
            // validate={validate.onlyReq}
            width="69.87%"
            name="equipment1"
            label="Equipment"
          />
          <InputField
            // validate={validate.onlyReq}
            width="14.83%"
            name="qty1"
            type="number"
            label="Quantity"
            cformat={SNumberFormat}
          />
        </CustomFlex>
        <CustomFlex width="29.35%" justifyContent="space-between">
          <InputField
            // validate={validate.onlyReq}
            width="69.87%"
            name="equipment2"
            label="Equipment"
          />
          <InputField
            // validate={validate.onlyReq}
            width="14.83%"
            name="qty2"
            type="number"
            label="Quantity"
            cformat={SNumberFormat}
          />
        </CustomFlex>
        <CustomFlex width="29.35%" justifyContent="space-between">
          <InputField
            // validate={validate.onlyReq}
            width="69.87%"
            name="equipment3"
            label="Equipment"
          />
          <InputField
            // validate={validate.onlyReq}
            width="14.83%"
            name="qty3"
            type="number"
            label="Quantity"
            cformat={SNumberFormat}
          />
        </CustomFlex>
      </CustomFlex>{" "}
      <div style={{ height: "8.8rem" }}></div>
      <ButtonFooter form={form} counter={counter} />
    </>
  );
};

export default ForthPage;
