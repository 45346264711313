import {
  ClickAwayListener,
  Fade,
  InputAdornment,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useRef, useState } from "react";
import { makeStyles } from "@mui/styles";

import InputField from "../inputField/InputField";
import Flex from "../../structure/flex";

const DropDown = ({
  onChange = () => {},
  label,
  name,
  children,
  width = "100",
  adjustment = "0",
  popperStyle,
  addText = "",
  addMethod,
  placeholder,
  options = [],
  value,
  filterBy,
  renderOption,
  onSelect,
  style,
  closeOnClick = false,
  validate,
  inputStyle,
  form,
}) => {
  const useStyles = makeStyles((theme) => ({
    sizing: {
      width: `${width}%`,
      "& > * > *": {
        borderColor: theme.palette.text.border,
      },
    },
    popper: {
      width: `${Number(width) - Number(adjustment)}%`,
      borderRadius: "0.4rem",
      marginLeft: "12px",
      ...popperStyle,
    },
    paper: {
      border: `2px solid ${theme.palette.background.default}`,
      boxShadow: `0 .2rem .4rem ${theme.palette.background.default}`,
    },
    menuList: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      backgroundColor: theme.palette.background.paper,
      padding: "0",

      "& > * > * > *": {
        fontSize: "1.5rem !important",
      },
      "& :hover": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
      "& > *": {
        border: `1px solid ${theme.palette.background.default}`,
      },
    },
    addText: {
      textAlign: "center",
      width: "100%",
      padding: "1rem 0",
      // marginTop: '.5rem',
      fontWeight: "600",
      fontSize: "1.6rem",
      backgroundColor: theme.palette.background.paper,
      borderTop: `1px solid ${theme.palette.background.default}`,

      "&:hover": {
        cursor: "pointer",
      },
    },
  }));
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  // console.log("look", form);
  const onInputChange = (evt) => {
    const {
      target: { value },
    } = evt;

    setSearchInput(value);
    onChange(value);
  };
  const handleClose = (event) => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    // 	return
    // }

    setOpen(false);
  };

  const showOptions = () => {
    return options
      .filter((each) => {
        let name = filterBy(each);
        return name?.toLowerCase().includes(searchInput?.toLowerCase());
      })
      .map((each) => {
        return (
          <MenuItem
            style={{
              width: "100%",
              // padding: "1rem 1.6rem"
            }}
            onClick={(e) => {
              onSelect(each);
              if (closeOnClick) {
                handleClose(e);
              }
            }}
          >
            {renderOption(each)}
          </MenuItem>
        );
      });
  };

  return (
    <Flex
      ref={anchorRef}
      className={classes.sizing}
      style={{ flexDirection: "column", ...style }}
    >
      <InputLabel htmlFor="state">{label}</InputLabel>
      <InputField
        onClick={() => setOpen((prev) => !prev)}
        onChange={onInputChange}
        validate={validate}
        name={name}
        variant="outlined"
        value={value}
        inputStyle={inputStyle}
        placeholder={placeholder}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon
                style={{
                  transform: `${open ? "rotate(-180deg)" : ""}`,
                  transition: "all .2s ease-in-out",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom"
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: "100",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Fade
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <div style={{ maxHeight: "20rem", overflowY: "overlay" }}>
                    <MenuList className={classes.menuList}>
                      {showOptions()}
                    </MenuList>
                  </div>
                  {addText && (
                    <div
                      onClick={(e) => {
                        handleClose(e);
                        addMethod(true);
                      }}
                      className={classes.addText}
                    >
                      <Typography variant="body1" color="primary">
                        {addText}
                      </Typography>
                    </div>
                  )}
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Flex>
  );
};

export default DropDown;
